import useClient from "./useClient";
import { useState } from "react";
import urls from "../api/urls";
import { addParamsToUrl } from "../utils/functions";
import { toast } from "react-toastify";

function useShop(props) {
  const [estimatedDelivery, setEstimatedDelivery] = useState(null);
  const [retrievedData, setRetrievedData] = useState({});
  const { isLoading, get, post } = useClient();
  const [concepts, setConcepts] = useState([]);
  const [outlets, setOutlets] = useState([]);
  const [drinks, setDrinks] = useState(null);
  const [order, setOrder] = useState(null);
  const [price, setPrice] = useState(null);
  const [menu, setMenu] = useState([]);

  const getBaseData = async (country, city, cb) => {
    if (retrievedData[city]) {
      setConcepts(retrievedData[city].concepts);
      setDrinks(retrievedData[city].drinks);
      setMenu(retrievedData[city].menu);
      if (typeof cb === "function") cb();
      return;
    }
    const { data, error } = await get(
      addParamsToUrl(urls.shop.menu, { country, city })
    );
    const { data: drinksData, error: error2 } = await get(urls.shop.getDrinks);
    if (error || !data?.data) return;
    setConcepts(data.data.concepts);
    setMenu(data.data.menu);
    setDrinks(drinksData.data.drinks);
    setRetrievedData({
      ...retrievedData,
      [city]: {
        concepts: data.data.concepts,
        menu: data.data.menu,
        drinks: drinksData.data.drinks,
      },
    });
    if (typeof cb === "function") cb();
  };

  const placeAndCompleteOrder = async (
    cart,
    orderType,
    city,
    deliveryTo,
    customerInformation,
    kiosk,
    cb
  ) => {
    const payload = {
      items: cart.map((_) => ({
        menu: _.item._id,
        quantity: _.quantity,
        variety: _.variety,
        drink: _.drink,
      })),
      orderType,
      deliveryTo,
      kiosk,
    };
    const { data, error } = await post(
      addParamsToUrl(urls.shop.placeorder, { city }),
      payload
    );
    if (error) return toast.warn(error.message || "Something went wrong!");
    const { error: error2, data: data2 } = await post(
      addParamsToUrl(urls.shop.checkout, { checkoutId: data.data.checkoutId }),
      { customerInformation, paymentMethod: "online" }
    );
    if (error2) return toast.warn(error2.message || "Something went wrong!");
    setOrder(data2.data);
    if (typeof cb === "function") cb();
  };

  const getOrder = async (id) => {
    const { data, error } = await get(urls.shop.getOrder + id);
    if (error || !data.data.order) return;
    setOrder(data.data.order);
  };

  const getMenuPrice = async (menu, city, variety, drink) => {
    const { data, error } = await get(
      addParamsToUrl(urls.shop.getPrice, { menu, city, variety, drink })
    );
    if (error || !data.data) return;
    setPrice(data.data);
  };

  const getDrinks = async (menu, city, variety) => {
    const { data, error } = await get(urls.shop.getDrinks);
    if (error || !data.data) return;
    console.log(data.data);
  };

  const getEstimatedDelivery = async (location) => {
    const { data, error } = await get(
      addParamsToUrl(urls.shop.getDelivery, location)
    );
    if (error || !data.data) return;
    setEstimatedDelivery(data.data);
  };

  const getPickupOutlets = async (country, city) => {
    const { data, error } = await get(
      addParamsToUrl(urls.shop.getPickUpOutlets, { country, city })
    );
    if (error || !data.data) return;
    setOutlets(data.data.outlets);
  };

  return {
    placeAndCompleteOrder,
    getEstimatedDelivery,
    loading: isLoading,
    getPickupOutlets,
    estimatedDelivery,
    getMenuPrice,
    getBaseData,
    getOrder,
    getDrinks,
    concepts,
    outlets,
    drinks,
    price,
    order,
    menu,
  };
}
export default useShop;
