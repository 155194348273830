import Gradient from "./Gradient";
import Menu from "./Menu";

function Explore(props) {
  return (
    <div>
      <Gradient title="Explore our Menu" />
      <Menu inpage />
    </div>
  );
}

export default Explore;
