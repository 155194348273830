import { useNavigate } from "react-router-dom";
import "../../css/menus.css";
import DataContext from "../../contexts/DataContext";
import { useContext, useEffect, useState } from "react";
import Image from "../Image";
import useModal from "../../hooks/useModal";
import Modal from "./Modal";
import Counter from "./Counter";
import Button from "../Button";
import Loader from "./Loader";
import useShop from "../../hooks/useShop";
import { generateUUID } from "../../utils/functions";
const Item = ({
  name,
  imageUrl,
  incart,
  item: _,
  cart,
  setCart,
  description,
  tonavigate,
  selectedCity,
  item,
  formatCurrency,
  varieties = [],
  drinks = [],
}) => {
  const { isOpened, openModal, closeModal } = useModal();
  const [selectedDrink, setSelectedDrink] = useState(null);
  const [selected, setSelected] = useState(
    varieties.filter((_) => item.availableVarieties.includes(_.name))[0]
  );
  const [count, setCount] = useState(1);
  // eslint-disable-next-line
  const navigate = useNavigate();
  const { getMenuPrice, loading, price } = useShop();

  useEffect(() => {
    getMenuPrice(item._id, selectedCity._id, selected?._id, selectedDrink?._id);
  }, [selected, selectedDrink]);

  return (
    <>
      <Modal
        className="_menu_modal"
        isVisible={isOpened}
        closeModal={closeModal}
      >
        <div className="_menu">
          <div className="_menu_details">
            <h2>Choose Variety</h2>
            <p>
              Choose between 1 from the options{" "}
              <span className="required">Required</span>{" "}
            </p>
            <br />
            {varieties.map((_, idx) => (
              <button
                disabled={!item.availableVarieties.includes(_.name)}
                onClick={() => setSelected(_)}
                key={idx}
                className={`v-btn ${
                  selected?.name === _.name ? "selected-v" : ""
                }`}
              >
                <span>{_.name}</span>
                {selected?.name === _.name ? (
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M22 11L19.56 8.21L19.9 4.52L16.29 3.7L14.4 0.5L11 1.96L7.6 0.5L5.71 3.69L2.1 4.5L2.44 8.2L0 11L2.44 13.79L2.1 17.49L5.71 18.31L7.6 21.5L11 20.03L14.4 21.49L16.29 18.3L19.9 17.48L19.56 13.79L22 11ZM9.09 15.72L5.29 11.91L6.77 10.43L9.09 12.76L14.94 6.89L16.42 8.37L9.09 15.72Z"
                      fill="#323232"
                    />
                  </svg>
                ) : (
                  <svg
                    width="34"
                    height="34"
                    viewBox="0 0 34 34"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.333 25.3334H18.6663V18.6667H25.333V15.3334H18.6663V8.66671H15.333V15.3334H8.66634V18.6667H15.333V25.3334ZM16.9997 33.6667C14.6941 33.6667 12.5275 33.2292 10.4997 32.3542C8.4719 31.4792 6.70801 30.2917 5.20801 28.7917C3.70801 27.2917 2.52051 25.5278 1.64551 23.5C0.770508 21.4723 0.333008 19.3056 0.333008 17C0.333008 14.6945 0.770508 12.5278 1.64551 10.5C2.52051 8.47226 3.70801 6.70837 5.20801 5.20837C6.70801 3.70837 8.4719 2.52087 10.4997 1.64587C12.5275 0.770874 14.6941 0.333374 16.9997 0.333374C19.3052 0.333374 21.4719 0.770874 23.4997 1.64587C25.5275 2.52087 27.2913 3.70837 28.7913 5.20837C30.2913 6.70837 31.4788 8.47226 32.3538 10.5C33.2288 12.5278 33.6663 14.6945 33.6663 17C33.6663 19.3056 33.2288 21.4723 32.3538 23.5C31.4788 25.5278 30.2913 27.2917 28.7913 28.7917C27.2913 30.2917 25.5275 31.4792 23.4997 32.3542C21.4719 33.2292 19.3052 33.6667 16.9997 33.6667Z"
                      fill="#311e06"
                    />
                  </svg>
                )}
              </button>
            ))}
            <br />
            <h2>Choose Drink</h2>
            <p>
              Choose between 1 from the options{" "}
              <span className="required">Optional</span>{" "}
            </p>
            <br />
            {drinks.map((_, idx) => (
              <button
                // disabled={!_.available}
                onClick={() => {
                  if (selectedDrink?._id === _._id) setSelectedDrink(null);
                  else setSelectedDrink(_);
                }}
                key={idx}
                className={`v-btn ${
                  selectedDrink?.name === _.name ? "selected-v" : ""
                }`}
              >
                <span>{_.name}</span>
                {selectedDrink?.name === _.name ? (
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M22 11L19.56 8.21L19.9 4.52L16.29 3.7L14.4 0.5L11 1.96L7.6 0.5L5.71 3.69L2.1 4.5L2.44 8.2L0 11L2.44 13.79L2.1 17.49L5.71 18.31L7.6 21.5L11 20.03L14.4 21.49L16.29 18.3L19.9 17.48L19.56 13.79L22 11ZM9.09 15.72L5.29 11.91L6.77 10.43L9.09 12.76L14.94 6.89L16.42 8.37L9.09 15.72Z"
                      fill="#323232"
                    />
                  </svg>
                ) : (
                  <svg
                    width="34"
                    height="34"
                    viewBox="0 0 34 34"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.333 25.3334H18.6663V18.6667H25.333V15.3334H18.6663V8.66671H15.333V15.3334H8.66634V18.6667H15.333V25.3334ZM16.9997 33.6667C14.6941 33.6667 12.5275 33.2292 10.4997 32.3542C8.4719 31.4792 6.70801 30.2917 5.20801 28.7917C3.70801 27.2917 2.52051 25.5278 1.64551 23.5C0.770508 21.4723 0.333008 19.3056 0.333008 17C0.333008 14.6945 0.770508 12.5278 1.64551 10.5C2.52051 8.47226 3.70801 6.70837 5.20801 5.20837C6.70801 3.70837 8.4719 2.52087 10.4997 1.64587C12.5275 0.770874 14.6941 0.333374 16.9997 0.333374C19.3052 0.333374 21.4719 0.770874 23.4997 1.64587C25.5275 2.52087 27.2913 3.70837 28.7913 5.20837C30.2913 6.70837 31.4788 8.47226 32.3538 10.5C33.2288 12.5278 33.6663 14.6945 33.6663 17C33.6663 19.3056 33.2288 21.4723 32.3538 23.5C31.4788 25.5278 30.2913 27.2917 28.7913 28.7917C27.2913 30.2917 25.5275 31.4792 23.4997 32.3542C21.4719 33.2292 19.3052 33.6667 16.9997 33.6667Z"
                      fill="#311e06"
                    />
                  </svg>
                )}
              </button>
            ))}
          </div>
          <div className="img-qnt">
            <div className="img_qnt_cnt">
              <Image src={imageUrl} />
            </div>
            <br />
            <div
              style={{ flexDirection: "column", textAlign: "center" }}
              className="flex justify-center align-center"
            >
              <h2>{name}</h2>
              <p>{description}</p>
              <span className="fs-16 category-name menu-price">
                {loading ? "loading..." : formatCurrency(price?.price)}
              </span>
              <br />
              <br />

              <Counter defaultCount={count} onChange={setCount} />
              <br />
              <Button
                onClick={() => {
                  setCart([
                    ...cart,
                    {
                      id: generateUUID(),
                      item: _,
                      quantity: count,
                      variety: selected._id,
                      priceCapture: price?.price,
                      drink: selectedDrink?._id,
                    },
                  ]);
                  if (tonavigate) navigate("/cart");
                  else closeModal();
                }}
                disabled={!selected || !count}
                maxWidth={300}
                width={"100%"}
                title={`Add ${count} for ${formatCurrency(
                  price?.price * count
                )}`}
              />
            </div>
          </div>
        </div>
      </Modal>
      {/* <div className="item-popup">
        
      </div> */}
      <div className="new-ui-menu">
        <button
          onClick={openModal}
          // onClick={() => {
          //   if (cart.find((__) => __.item._id === _._id))
          //     setCart(
          //       cart.map((__) => {
          //         if (__.item._id === _._id) {
          //           console.log(__.quantity + 1);
          //           __.quantity += 1;
          //         }
          //         return __;
          //       })
          //     );
          //   else setCart([...cart, { item: _, quantity: 1 }]);
          //   if (tonavigate) navigate("/cart");
          // }}
          className="add-item"
        >
          {incart ? <span className="incart-count">{incart}</span> : null}
          <svg
            width="31"
            height="30"
            viewBox="0 0 31 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.1042 21.9766H17.0234V16.1383H22.8617V13.2191H17.0234V7.38082H14.1042V13.2191H8.26595V16.1383H14.1042V21.9766ZM15.5638 29.2744C13.5447 29.2744 11.6473 28.8913 9.87148 28.125C8.09567 27.3587 6.55095 26.3188 5.23733 25.0052C3.92372 23.6916 2.88377 22.1468 2.11749 20.371C1.35122 18.5952 0.968079 16.6978 0.968079 14.6787C0.968079 12.6596 1.35122 10.7622 2.11749 8.98635C2.88377 7.21054 3.92372 5.66582 5.23733 4.3522C6.55095 3.03859 8.09567 1.99864 9.87148 1.23236C11.6473 0.466085 13.5447 0.0829468 15.5638 0.0829468C17.5829 0.0829468 19.4803 0.466085 21.2562 1.23236C23.032 1.99864 24.5767 3.03859 25.8903 4.3522C27.2039 5.66582 28.2439 7.21054 29.0102 8.98635C29.7764 10.7622 30.1596 12.6596 30.1596 14.6787C30.1596 16.6978 29.7764 18.5952 29.0102 20.371C28.2439 22.1468 27.2039 23.6916 25.8903 25.0052C24.5767 26.3188 23.032 27.3587 21.2562 28.125C19.4803 28.8913 17.5829 29.2744 15.5638 29.2744Z"
              fill="black"
            />
          </svg>
        </button>
        <div className="details flex flex-column">
          <span className="category-name">{name}</span>
          <br />
          <p>{description}</p>
          {/* <p className="delivery-duration">
              Delivery in <span>25-30 mins</span>
            </p> */}
          <br />
          <span className="category-name menu-price">
            {loading ? "loading..." : formatCurrency(price?.price)}
          </span>
        </div>
        <div className="img">
          <Image src={imageUrl} alt="" />
        </div>
      </div>
    </>
  );
};

function Menu({ inpage, tonavigate }) {
  const navigateExplore = () => navigate("/explore");
  const {
    concepts,
    menu,
    drinks,
    formatCurrency,
    selectedCity,
    cart,
    setCart,
  } = useContext(DataContext);
  const [selectedConcept, setSelectedConcept] = useState(concepts[0]);
  const navigate = useNavigate();

  const Filters = ({ className }) => (
    <div className={className}>
      {concepts.map((c, idx) => (
        <button
          className={selectedConcept?._id === c._id ? "active" : ""}
          onClick={() => setSelectedConcept(c)}
          key={idx}
        >
          {c.name}
        </button>
      ))}
    </div>
  );
  return (
    <div className="container">
      <br />
      <br />
      {!inpage && (
        <>
          <h2 className="new-heading">Explore our Menu</h2>
          <br />
        </>
      )}
      <div className="filter-splitter">
        <Filters className="filters filters-mobile" />
        <div className="filter-categories">
          <div className="filter-category">
            <span className="filter-title">Filter Category</span>
            <Filters className="filters" />
          </div>
        </div>
        <div className="list-search">
          <div className="search-title flex">
            <h3>{selectedConcept?.name}</h3>
            <div className="search flex">
              <div className="input flex">
                <input type="text" placeholder="Search..." />
                <div className="s-icon">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.9167 9.66667H10.2583L10.025 9.44167C10.8417 8.49167 11.3333 7.25833 11.3333 5.91667C11.3333 2.925 8.90833 0.5 5.91667 0.5C2.925 0.5 0.5 2.925 0.5 5.91667C0.5 8.90833 2.925 11.3333 5.91667 11.3333C7.25833 11.3333 8.49167 10.8417 9.44167 10.025L9.66667 10.2583V10.9167L13.8333 15.075L15.075 13.8333L10.9167 9.66667ZM5.91667 9.66667C3.84167 9.66667 2.16667 7.99167 2.16667 5.91667C2.16667 3.84167 3.84167 2.16667 5.91667 2.16667C7.99167 2.16667 9.66667 3.84167 9.66667 5.91667C9.66667 7.99167 7.99167 9.66667 5.91667 9.66667Z"
                      fill="black"
                    />
                  </svg>
                </div>
              </div>
              {!inpage && (
                <button onClick={navigateExplore}>View All Menu</button>
              )}
            </div>
          </div>
          <div className="menu-categories-list in-menu-list">
            {menu
              .filter((_) => _.concept._id === selectedConcept?._id)
              .map((_, idx) => (
                <Item
                  {..._}
                  drinks={drinks}
                  selectedCity={selectedCity}
                  tonavigate={tonavigate}
                  incart={cart.find((__) => __.item._id === _._id)?.quantity}
                  cart={cart}
                  item={_}
                  imageUrl={_.images[0]}
                  setCart={setCart}
                  formatCurrency={formatCurrency}
                  key={idx}
                  varieties={_.varieties}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Menu;
