import { useContext } from "react";
import DataContext from "../../contexts/DataContext";
import Image from "../Image";
import { Link } from "react-router-dom";

const Card = ({ name, logo }) => (
  <Link className="menu-category-item">
    <div className="img">
      <Image src={logo} alt="" />
    </div>
    <div className="details">
      <span className="category-name">{name}</span>
      <p className="delivery-duration">
        Delivery in <span>25-30 mins</span>
      </p>
    </div>
  </Link>
);

function Categories(props) {
  const { concepts } = useContext(DataContext);
  return (
    <div className="container">
      <br />
      <br />
      <h2 className="new-heading">Best Selling Categories</h2>
      <br />
      <div className={`menu-categories-list length-${concepts?.length}`}>
        {concepts.map((_, idx) => (
          <Card {..._} key={idx} />
        ))}
      </div>
    </div>
  );
}

export default Categories;
