import { useEffect, useState } from "react";

function Counter({ defaultCount = 0, onChange, width = 35 }) {
  const [count, setCount] = useState(defaultCount);

  const increment = () => {
    if (typeof onChange === "function") onChange(defaultCount + 1);
    setCount(defaultCount + 1);
  };
  const decrement = () => {
    if (count <= 0) return;
    if (typeof onChange === "function") onChange(defaultCount - 1);
    setCount(defaultCount - 1);
  };

  useEffect(() => {
    console.log("=========================+");
    console.log(count);
    console.log("=========================+");
  }, [count]);

  return (
    <div className="flex counter align-center">
      <button
        disabled={!defaultCount}
        style={{ width, height: width }}
        onClick={decrement}
      >
        <svg
          width="34"
          height="34"
          viewBox="0 0 34 34"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.66634 18.6667H25.333V15.3334H8.66634V18.6667ZM16.9997 33.6667C14.6941 33.6667 12.5275 33.2292 10.4997 32.3542C8.4719 31.4792 6.70801 30.2917 5.20801 28.7917C3.70801 27.2917 2.52051 25.5278 1.64551 23.5C0.770508 21.4723 0.333008 19.3056 0.333008 17C0.333008 14.6945 0.770508 12.5278 1.64551 10.5C2.52051 8.47226 3.70801 6.70837 5.20801 5.20837C6.70801 3.70837 8.4719 2.52087 10.4997 1.64587C12.5275 0.770874 14.6941 0.333374 16.9997 0.333374C19.3052 0.333374 21.4719 0.770874 23.4997 1.64587C25.5275 2.52087 27.2913 3.70837 28.7913 5.20837C30.2913 6.70837 31.4788 8.47226 32.3538 10.5C33.2288 12.5278 33.6663 14.6945 33.6663 17C33.6663 19.3056 33.2288 21.4723 32.3538 23.5C31.4788 25.5278 30.2913 27.2917 28.7913 28.7917C27.2913 30.2917 25.5275 31.4792 23.4997 32.3542C21.4719 33.2292 19.3052 33.6667 16.9997 33.6667Z"
            fill="#FBC98E"
          />
        </svg>
      </button>
      <span>{defaultCount}</span>
      <button style={{ width, height: width }} onClick={increment}>
        <svg
          width="34"
          height="34"
          viewBox="0 0 34 34"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.333 25.3334H18.6663V18.6667H25.333V15.3334H18.6663V8.66671H15.333V15.3334H8.66634V18.6667H15.333V25.3334ZM16.9997 33.6667C14.6941 33.6667 12.5275 33.2292 10.4997 32.3542C8.4719 31.4792 6.70801 30.2917 5.20801 28.7917C3.70801 27.2917 2.52051 25.5278 1.64551 23.5C0.770508 21.4723 0.333008 19.3056 0.333008 17C0.333008 14.6945 0.770508 12.5278 1.64551 10.5C2.52051 8.47226 3.70801 6.70837 5.20801 5.20837C6.70801 3.70837 8.4719 2.52087 10.4997 1.64587C12.5275 0.770874 14.6941 0.333374 16.9997 0.333374C19.3052 0.333374 21.4719 0.770874 23.4997 1.64587C25.5275 2.52087 27.2913 3.70837 28.7913 5.20837C30.2913 6.70837 31.4788 8.47226 32.3538 10.5C33.2288 12.5278 33.6663 14.6945 33.6663 17C33.6663 19.3056 33.2288 21.4723 32.3538 23.5C31.4788 25.5278 30.2913 27.2917 28.7913 28.7917C27.2913 30.2917 25.5275 31.4792 23.4997 32.3542C21.4719 33.2292 19.3052 33.6667 16.9997 33.6667Z"
            fill="#F7941D"
          />
        </svg>
      </button>
    </div>
  );
}

export default Counter;
