const urls = {
  messages: {
    baseUrl: "/v1/messages",
  },
  contact: {
    baseUrl: "/v1/contact",
  },
  news: {
    baseUrl: "/v1/news/signup",
  },
  files: {
    baseUrl: "/v1/files/",
    image: "/image",
  },
  configs: {
    baseUrl: "/v2/configs",
    cities: "/v2/configs/cities",
    countries: "/v2/configs/countries",
  },
  shop: {
    menu: "/v2/shop/menu",
    getOrder: "/v2/shop/order/",
    getPrice: "/v2/shop/price",
    getDrinks: "/v2/shop/drinks",
    checkout: "/v2/shop/submit-order",
    placeorder: "/v2/shop/order-request",
    getPickUpOutlets: "/v2/shop/outlets",
    getDelivery: "/v2/shop/estimated-delivery",
  },
};

export default urls;
