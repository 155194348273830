import { Contact } from "../components/Contact";
import { Banner } from "../components/new-ui/Banner";
import Categories from "../components/new-ui/Categories";
import Menu from "../components/new-ui/Menu";

function Home(props) {
  return (
    <div>
      <Banner />
      <Categories />
      <br />
      <Menu tonavigate />
      <Contact />
    </div>
  );
}

export default Home;
