import { NavLink } from "react-router-dom";
import bannerImg from "../../assets/images/banner2.png";

export const Banner = () => {
  return (
    <div className="home_banner">
      <section>
        <div className="home_banner-left">
          <h1>Get your favorite food from our iCart</h1>
          <p>Explore our menus and see whats available closest to you.</p>

          <div className="home_banner-btns">
            <NavLink to={"/explore"} className="banner_btn">
              Explore our Menu
            </NavLink>
            <NavLink to={"/track-order"} className="banner_btn">
              Track your Order
            </NavLink>
          </div>
        </div>
        <div className="home_banner-right">
          <svg
            className="banner_star star one"
            width="53"
            height="53"
            viewBox="0 0 53 53"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M26.1838 0H26.1841V2.67029e-05C26.1841 14.4726 37.9163 26.2049 52.3888 26.205C37.9163 26.2051 26.1841 37.9375 26.1841 52.41C26.184 52.41 26.1839 52.41 26.1838 52.41L26.1835 52.41C26.1835 37.9445 14.4628 26.2166 -1.33514e-05 26.205C14.4628 26.1934 26.1835 14.4655 26.1835 2.67029e-05V0H26.1838Z"
              fill="var(--yellow-tint-2)"
            />
          </svg>
          <svg
            className="banner_star star two"
            width="72"
            height="72"
            viewBox="0 0 53 53"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M26.1838 0H26.1841V2.67029e-05C26.1841 14.4726 37.9163 26.2049 52.3888 26.205C37.9163 26.2051 26.1841 37.9375 26.1841 52.41C26.184 52.41 26.1839 52.41 26.1838 52.41L26.1835 52.41C26.1835 37.9445 14.4628 26.2166 -1.33514e-05 26.205C14.4628 26.1934 26.1835 14.4655 26.1835 2.67029e-05V0H26.1838Z"
              fill="var(--yellow-tint-3)"
            />
          </svg>
          <svg
            className="banner_star star three"
            width="53"
            height="53"
            viewBox="0 0 53 53"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M26.1838 0H26.1841V2.67029e-05C26.1841 14.4726 37.9163 26.2049 52.3888 26.205C37.9163 26.2051 26.1841 37.9375 26.1841 52.41C26.184 52.41 26.1839 52.41 26.1838 52.41L26.1835 52.41C26.1835 37.9445 14.4628 26.2166 -1.33514e-05 26.205C14.4628 26.1934 26.1835 14.4655 26.1835 2.67029e-05V0H26.1838Z"
              fill="var(--yellow-tint-5)"
            />
          </svg>
          <svg
            className="banner_star star four"
            width="53"
            height="53"
            viewBox="0 0 53 53"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M26.1838 0H26.1841V2.67029e-05C26.1841 14.4726 37.9163 26.2049 52.3888 26.205C37.9163 26.2051 26.1841 37.9375 26.1841 52.41C26.184 52.41 26.1839 52.41 26.1838 52.41L26.1835 52.41C26.1835 37.9445 14.4628 26.2166 -1.33514e-05 26.205C14.4628 26.1934 26.1835 14.4655 26.1835 2.67029e-05V0H26.1838Z"
              fill="var(--yellow-tint-2)"
            />
          </svg>
          <img src={bannerImg} alt="banner" />
        </div>
      </section>
    </div>
  );
};
