import axios from "axios";
import store from "../utils/store";

const baseURL = process.env.REACT_APP_API_BASE_URL;

const client = axios.create({
  baseURL: baseURL,
  headers: {
    "x-access-token": store.getItem(store.accessTokenKey),
  },
});

export default client;
