import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";
import { useEffect, useState, useRef } from "react";
import DataContext from "./contexts/DataContext";
import { getItem, setItem } from "./utils/store";
import Home from "./routes/Home";
import ViewProduct from "./routes/ViewProduct";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LocationSearchInput from "./components/PlacesAutoComplete";
import { formatIntCurrency, reverseGeocode } from "./utils/functions";
import useLocation2 from "./hooks/useLocation";
import Cart from "./routes/Cart";
import Explore from "./components/new-ui/Explore";
import TrackOrder from "./components/new-ui/TrackOrder";
import useConfig from "./hooks/useConfig";
import useShop from "./hooks/useShop";
import Loader from "./components/new-ui/Loader";
import icart from "./assets/images/icart.png";

function App(props) {
  const [deliveryAddress, setDeliveryAddress] = useState(null);
  const [selectedOutlet, setSelectedOutlet] = useState(null);
  const [deliveryCost, setDeliveryCost] = useState(null);
  const [outlets, setOutlets] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [isOpened, setIsOpened] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [isSync, setIsSync] = useState(false);
  const [cart, setCart] = useState([]);
  const markerRef = useRef(null); // Reference to the marker
  const { pathname } = useLocation();
  const toggleLocationModal = () => setIsOpened(!isOpened);
  const { cities, getCities, loading: loading1 } = useConfig();
  const { getBaseData, concepts, menu, drinks } = useShop();
  const {
    position: { latitude, longitude },
    getLocation,
    resetLocation,
  } = useLocation2();

  const getAddress = async (lat = latitude, lng = longitude) => {
    const locationData = await reverseGeocode(lat, lng);
    if (locationData) {
      setDeliveryAddress({
        address:
          locationData?.address_components[1]?.long_name ||
          locationData.formatted_address,
        lat,
        lng,
      });
    }
  };

  useEffect(() => {
    getLocation();
    getCities((_) => {
      const defaultCity = _.find((x) => x.code === "ABJ");
      setSelectedCity(defaultCity);
    });
  }, []);

  useEffect(() => {
    const reloadlinks = ["/", "/explore"];
    if (selectedCity) {
      if (reloadlinks.includes(pathname)) {
        setIsReady(false);
        getBaseData(selectedCity?.country?._id, selectedCity?._id, () =>
          setIsReady(true)
        );
      } else {
        setIsReady(true);
      }
    }
  }, [selectedCity, pathname]);

  useEffect(() => {
    const cart = getItem("ucart") || [];
    setCart(cart);
    setIsSync(true);
  }, []);

  useEffect(() => {
    if (latitude) getAddress();
  }, [latitude]);

  useEffect(() => {
    if (isSync) {
      setItem("ucart", cart);
    }
  }, [cart, isSync]);

  useEffect(() => {
    const initMap = () => {
      let location = deliveryAddress;
      let map = new window.google.maps.Map(document.getElementById("map"), {
        center: location,
        zoom: 16,
      });

      markerRef.current = new window.google.maps.Marker({
        position: location,
        map: map,
      });

      // Add click listener to get coordinates of clicked location
      map.addListener("click", (event) => {
        const clickedLat = event.latLng.lat();
        const clickedLng = event.latLng.lng();
        console.log("Clicked coordinates:", clickedLat, clickedLng);
        // Update the marker position
        markerRef.current.setPosition({ lat: clickedLat, lng: clickedLng });
        // Update the delivery address state
        getAddress(clickedLat, clickedLng);
      });

      outlets.forEach((outlet) => {
        const marker = new window.google.maps.Marker({
          position: { lat: outlet.location.lat, lng: outlet.location.lng },
          map: map,
          icon: {
            url: icart, // Custom image for the marker
            scaledSize: new window.google.maps.Size(40, 40), // Adjust the size
          },
          title: outlet.name, // Tooltip text
        });

        // Optional: Add a click listener for each marker
        marker.addListener("click", () => {
          setSelectedOutlet(outlet);
          setDeliveryAddress({
            lat: outlet.location.lat,
            lng: outlet.location.lng,
            address: outlet.location.label,
          });
          toggleLocationModal();
        });
      });
    };

    if (window.google && window.google.maps && isOpened) {
      initMap();
    } else {
      // toast.error("Google Maps API not loaded");
    }
  }, [isOpened, outlets]);

  if (!isReady)
    return (
      <div className="app-load flex justify-center align-center">
        <Loader loading />
      </div>
    );

  return (
    <DataContext.Provider
      value={{
        cart,
        menu,
        cities,
        drinks,
        setCart,
        concepts,
        deliveryCost,
        selectedCity,
        setSelectedCity,
        deliveryAddress,
        selectedOutlet,
        setSelectedOutlet,
        loading: loading1,
        setDeliveryAddress,
        toggleLocationModal: (outelets) => {
          setOutlets(outelets);
          toggleLocationModal();
        },
        formatCurrency: (amount) =>
          formatIntCurrency(
            amount || 0,
            `en-${selectedCity?.country?.code}`,
            selectedCity?.country?.currency
          ),
      }}
    >
      <div className="root-div">
        <Header />
        {isOpened && (
          <div className="search-container">
            {/* <h2 style={{ color: "white" }}>
                {JSON.stringify({ deliveryAddress })}
              </h2> */}
            <button onClick={toggleLocationModal} className="btn-cancel">
              Close
            </button>
            <div className="location-container">
              <LocationSearchInput
                onChange={(data) => {
                  setDeliveryAddress(data);
                  toggleLocationModal();
                }}
              />
            </div>
            <button
              onClick={() => {
                resetLocation();
                getLocation();
                toggleLocationModal();
              }}
              className="btn-cancel"
            >
              Use my current location
            </button>
            <span
              style={{
                marginBottom: 20,
                color: "white",
                display: "block",
                fontSize: "2em",
              }}
            >
              {deliveryAddress?.address}
            </span>
            <div
              id="map"
              style={{ height: "calc(100% - 300px)", width: "100%" }}
            ></div>
          </div>
        )}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/explore" element={<Explore />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/track-order" element={<TrackOrder />} />
          <Route path="/menu/:id" element={<ViewProduct />} />
        </Routes>
        <Footer />
      </div>
      <ToastContainer />
    </DataContext.Provider>
  );
}

export default App;
