import { useState } from "react";
import urls from "../api/urls";
import useClient from "./useClient";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const useOrderSystem = () => {
  const { isLoading, post, get, errorMessage } = useClient();
  const [checkoutInfo, setCheckoutInfo] = useState(null);
  const [order, setOrder] = useState(null);
  const navigate = useNavigate();

  const placeOrder = async (fdata) => {
    const { data, error } = await post(urls.products.placeOrder, fdata);
    if (error) return toast.warn(error.message);
    setOrder(data.data.order);
    toast.success(data.message);
    navigate(`/track-order?id=${data.data.order.id}`);
  };

  const getOrder = async (id) => {
    const { data, error } = await get(urls.products.details.replace(":id", id));
    if (error) return toast.warn(error.message);
    setOrder(data.data.order);
    toast.success(data.message);
  };

  const checkout = async (id) => {
    const { data, error } = await post(
      urls.products.checkout.replace(":id", id)
    );
    if (error) return toast.warn(error.message);
    setCheckoutInfo(data.data);
    window.open(data.data.checkoutUrl);
    toast.success(data.message);
  };

  return {
    errorMessage,
    placeOrder,
    order,
    getOrder,
    checkout,
    checkoutInfo,
    isLoading: isLoading,
  };
};

export default useOrderSystem;
