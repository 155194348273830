import useClient from "./useClient";
import { useState } from "react";
import urls from "../api/urls";

function useConfig(props) {
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const { loading, get } = useClient();

  const getCountries = async () => {
    const { data, error } = await get(urls.configs.countries);
    if (error) return;
    setCountries(data.countries);
  };

  const getCities = async (cb) => {
    const { data, error } = await get(urls.configs.cities);
    if (error || !data?.data) return;
    setCities(data.data.cities);
    if (typeof cb === "function") cb(data.data.cities);
  };

  return {
    getCountries,
    getCities,
    countries,
    loading,
    cities,
  };
}
export default useConfig;
