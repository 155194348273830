import React from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

class LocationSearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { address: "" };
  }

  handleChange = (address) => {
    this.setState({ address });
  };

  handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        if (typeof this.props.onChange === "function")
          this.props.onChange({
            address,
            lat: latLng.lat,
            lng: latLng.lng,
          });
      })
      .catch((error) => console.error("Error", error));
  };

  render() {
    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="locations-container flex flex-column justify-center align-center">
            <input
              {...getInputProps({
                placeholder: "Search Places ...",
                className: "location-search-input input",
              })}
            />
            {loading || suggestions.length ? (
              <div className="autocomplete-dropdown-container">
                {loading && <div>Loading...</div>}
                {suggestions.map((suggestion, idx) => {
                  const className = `suggestion ${
                    suggestion.active
                      ? "suggestion-item--active"
                      : "suggestion-item"
                  }`;
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? { backgroundColor: "#fafafa", cursor: "pointer" }
                    : { backgroundColor: "#ffffff", cursor: "pointer" };
                  return (
                    <div
                      key={idx}
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                    >
                      <span>{suggestion.formattedSuggestion.mainText}</span>
                      <span>
                        {suggestion.formattedSuggestion.secondaryText}
                      </span>
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

export default LocationSearchInput;
