import { useContext, useEffect, useState } from "react";
import Counter from "./Counter";
import { useNavigate } from "react-router-dom";
import DataContext from "../../contexts/DataContext";
import Image from "../Image";
import store from "../../utils/store";
import useShop from "../../hooks/useShop";
import Loader from "./Loader";
import Modal from "./Modal";
import useModal from "../../hooks/useModal";
import icart from "../../assets/images/icart.png";
import Button from "../Button";

const deliveryModes = ["delivery", "pickup"];

const Item = ({
  quantity,
  variety,
  item,
  setCart,
  priceCapture,
  formatCurrency,
  id,
  drink,
  drinks,
}) => (
  <div className="cart-item flex new-ui-card">
    <div className="menu-img">
      <Image src={item.images[0]} alt="" />
    </div>
    <div className="menu-details">
      <span className="menu-name">{item.name}</span>
      <p>{item.description}</p>
      <br />
      <div style={{ gap: 10 }} className="flex gap-10 ">
        {variety ? (
          <span
            style={{
              width: "fit-content",
              backgroundColor: "var(--alerts-success)",
            }}
            className="variety required"
          >
            {item.varieties.find((_) => _._id === variety)?.name}
          </span>
        ) : null}
        {drink ? (
          <span
            style={{
              width: "fit-content",
              backgroundColor: "var(--alerts-info)",
            }}
            className="variety required"
          >
            {drinks?.find((_) => _._id === drink)?.name}
          </span>
        ) : null}
      </div>
    </div>
    <div className="price-counter">
      <span className="cart-menu-price">
        {formatCurrency(priceCapture * quantity)}
      </span>
      <Counter
        onChange={(count) => {
          if (!count)
            return setCart((_cart) => _cart.filter((_) => _.id !== id));
          setCart((_cart) =>
            _cart.map((__) => {
              if (__.id === id) {
                __.quantity = count;
              }
              return __;
            })
          );
        }}
        defaultCount={quantity}
      />
    </div>
  </div>
);

function UCart(props) {
  const {
    toggleLocationModal,
    formatCurrency,
    deliveryAddress,
    setDeliveryAddress,
    cart,
    setCart,
    selectedCity,
    drinks,
    selectedOutlet,
    setSelectedOutlet,
  } = useContext(DataContext);
  const [phoneNumber, setPhoneNumber] = useState(store.getItem("phoneNumber"));
  const [email, setEmail] = useState(store.getItem("email"));
  const [name, setName] = useState(store.getItem("name"));
  const [deliveryMode, setDeliveryMode] = useState(deliveryModes[0]);

  const navigate = useNavigate();
  const navigateExplore = () => navigate("/explore");
  const total = cart.reduce((p, c) => p + c.priceCapture * c.quantity, 0);
  const vat = total * ((selectedCity?.country?.vat || 0) / 100);
  const {
    placeAndCompleteOrder,
    order,
    loading,
    estimatedDelivery,
    getEstimatedDelivery,
    getPickupOutlets,
    outlets,
  } = useShop();

  const { openModal, closeModal, isOpened } = useModal();
  const {
    openModal: openOutlets,
    closeModal: closeOutlets,
    isOpened: outletsOpen,
  } = useModal();

  useEffect(() => {
    if (phoneNumber) store.setItem("phoneNumber", phoneNumber);
  }, [phoneNumber]);

  useEffect(() => {
    if (email) store.setItem("email", email);
  }, [email]);

  useEffect(() => {
    if (name) store.setItem("name", name);
  }, [name]);

  useEffect(() => {
    if (deliveryAddress?.lat && deliveryMode === deliveryModes[0])
      getEstimatedDelivery({ ...deliveryAddress, city: selectedCity?._id });
  }, [deliveryAddress, deliveryMode]);

  useEffect(() => {
    if (selectedCity)
      getPickupOutlets(selectedCity.country._id, selectedCity._id);
  }, [selectedCity]);

  return (
    <div className="container">
      <Modal isVisible={outletsOpen}>
        <div className="search-container">
          {/* <h2 style={{ color: "white" }}>
                {JSON.stringify({ deliveryAddress })}
              </h2> */}
          <button onClick={closeOutlets} className="btn-cancel">
            Close
          </button>
          <div className="icarts-lists">
            {outlets.map((_) => (
              <div className="icart-display" key={_._id}>
                <img src={icart} className="icart-img" alt="icart" />
                <div className="icart-desc">
                  <h3>{_.name}</h3>
                  <a
                    href={`https://google.com/maps/?q=${_.location.lat},${_.location.lng}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {_.location.label}
                  </a>
                  <Button
                    onClick={() => {
                      setSelectedOutlet(_);
                      setDeliveryAddress({
                        lat: _.location.lat,
                        lng: _.location.lng,
                        address: _.location.label,
                      });
                      closeOutlets();
                    }}
                    title="Choose iCart"
                  />
                  {/* <div className="menu-images">
                  {_.menu.map((menu) => (
                    <div key={menu._id}>
                      <Image src={menu.menu.images[0]} />
                    </div>
                  ))}
                </div> */}
                </div>
              </div>
            ))}
          </div>
        </div>
      </Modal>
      <Modal isVisible={isOpened}>
        <h2>Order Completed</h2>
        <br />
        <p>
          Thank you for your order! 🎉
          <br />
          Your order has been successfully placed and is being processed. We'll
          notify you when it's ready for delivery or pickup. You can track your
          order status in real-time{" "}
          <a
            href={`/track-order?id=${order?.order?.id}`}
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </p>
        <br />
        <br />
        <br />
        <div style={{ gap: 10 }} className="flex justify-between align-center">
          <a style={{ flex: 1 }} target="_blank" href={order?.checkoutUrl}>
            <button
              style={{ width: "100%" }}
              className="flex pay-btn btn align-center justify-center cormorant f700"
            >
              <svg
                width="27"
                height="28"
                viewBox="0 0 27 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.6334 20.1333L21.0334 10.7333L19.1667 8.86666L11.6334 16.4L7.83342 12.6L5.96675 14.4667L11.6334 20.1333ZM13.5001 27.3333C11.6556 27.3333 9.9223 26.9833 8.30008 26.2833C6.67786 25.5833 5.26675 24.6333 4.06675 23.4333C2.86675 22.2333 1.91675 20.8222 1.21675 19.2C0.516748 17.5778 0.166748 15.8444 0.166748 14C0.166748 12.1555 0.516748 10.4222 1.21675 8.79999C1.91675 7.17777 2.86675 5.76666 4.06675 4.56666C5.26675 3.36666 6.67786 2.41666 8.30008 1.71666C9.9223 1.01666 11.6556 0.666656 13.5001 0.666656C15.3445 0.666656 17.0779 1.01666 18.7001 1.71666C20.3223 2.41666 21.7334 3.36666 22.9334 4.56666C24.1334 5.76666 25.0834 7.17777 25.7834 8.79999C26.4834 10.4222 26.8334 12.1555 26.8334 14C26.8334 15.8444 26.4834 17.5778 25.7834 19.2C25.0834 20.8222 24.1334 22.2333 22.9334 23.4333C21.7334 24.6333 20.3223 25.5833 18.7001 26.2833C17.0779 26.9833 15.3445 27.3333 13.5001 27.3333Z"
                  fill="white"
                />
              </svg>
              <span>Checkout</span>
            </button>
          </a>
          <button
            style={{
              backgroundColor: "var(--yellow-main)",
              borderRadius: 4,
              color: "white",
            }}
            onClick={closeModal}
          >
            Close
          </button>
        </div>
      </Modal>
      <div className="cart-display">
        <div className="cart-items">
          <div className="flex justify-between align-center">
            <h3 className="order-text">Your Order</h3>
            <button
              onClick={navigateExplore}
              className="add-btn"
              title="Add more items"
            >
              Add more Items
            </button>
          </div>
          <br />
          <br />
          {cart.map((_, idx) => (
            <Item
              {..._}
              formatCurrency={formatCurrency}
              setCart={setCart}
              key={_.id}
              drinks={drinks}
            />
          ))}
        </div>
        <div className="preview">
          <div className="p-head">Cart Summary</div>
          <table>
            <tr>
              <td>Sub Totla</td>
              <td>{formatCurrency(total)}</td>
            </tr>
            <tr>
              <td>VAT</td>
              <td>{formatCurrency(vat)}</td>
            </tr>
            <tr>
              <td>Est. Delivery Fee</td>
              <td>
                {deliveryMode === deliveryModes[0]
                  ? formatCurrency(estimatedDelivery?.cost || 0)
                  : "-"}
              </td>
            </tr>
            <tr>
              <td>Est. Delivery Time</td>
              <td>
                {!estimatedDelivery || deliveryMode === deliveryModes[1]
                  ? "-"
                  : estimatedDelivery.duration}
              </td>
            </tr>
          </table>
          <div className="total">
            <div className="flex justify-between align-center">
              <span>Total to pay</span>
              <span>
                {formatCurrency(
                  total +
                    vat +
                    (deliveryMode === deliveryModes[0]
                      ? estimatedDelivery?.cost || 0
                      : 0)
                )}
              </span>
            </div>
          </div>
          <div className="delivery-mode flex justify-between alingn-center">
            <button
              onClick={() => {
                setDeliveryMode(deliveryModes[0]);
                setSelectedOutlet(null);
              }}
              className={deliveryMode === deliveryModes[0] ? "selected" : ""}
            >
              <svg
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.82693 17.3559C5.83446 17.3559 4.99085 17.0086 4.29612 16.3138C3.60138 15.6191 3.25401 14.7755 3.25401 13.783H0.87207V10.2101C0.87207 8.90004 1.33853 7.77854 2.27146 6.84561C3.20439 5.91268 4.32589 5.44622 5.63596 5.44622H10.3998V11.4011H14.5683L18.7367 6.22035V3.06427H15.1637V0.682327H18.7367C19.3917 0.682327 19.9524 0.915559 20.4189 1.38202C20.8854 1.84849 21.1186 2.40924 21.1186 3.06427V7.05403L15.7592 13.783H10.3998C10.3998 14.7755 10.0525 15.6191 9.35775 16.3138C8.66301 17.0086 7.81941 17.3559 6.82693 17.3559ZM6.82693 14.974C7.16437 14.974 7.44723 14.8599 7.6755 14.6316C7.90377 14.4033 8.0179 14.1205 8.0179 13.783H5.63596C5.63596 14.1205 5.75009 14.4033 5.97836 14.6316C6.20663 14.8599 6.48949 14.974 6.82693 14.974ZM4.44499 4.25524V1.8733H10.3998V4.25524H4.44499ZM21.1186 17.3559C20.1261 17.3559 19.2825 17.0086 18.5878 16.3138C17.893 15.6191 17.5457 14.7755 17.5457 13.783C17.5457 12.7905 17.893 11.9469 18.5878 11.2522C19.2825 10.5575 20.1261 10.2101 21.1186 10.2101C22.1111 10.2101 22.9547 10.5575 23.6494 11.2522C24.3441 11.9469 24.6915 12.7905 24.6915 13.783C24.6915 14.7755 24.3441 15.6191 23.6494 16.3138C22.9547 17.0086 22.1111 17.3559 21.1186 17.3559ZM21.1186 14.974C21.456 14.974 21.7389 14.8599 21.9672 14.6316C22.1954 14.4033 22.3096 14.1205 22.3096 13.783C22.3096 13.4456 22.1954 13.1627 21.9672 12.9345C21.7389 12.7062 21.456 12.592 21.1186 12.592C20.7812 12.592 20.4983 12.7062 20.27 12.9345C20.0418 13.1627 19.9276 13.4456 19.9276 13.783C19.9276 14.1205 20.0418 14.4033 20.27 14.6316C20.4983 14.8599 20.7812 14.974 21.1186 14.974ZM13.9728 24.5018L6.82693 20.9289H11.5908V18.5469L18.7367 22.1198H13.9728V24.5018ZM3.25401 11.4011H8.0179V7.82816H5.63596C4.98092 7.82816 4.42018 8.06139 3.95371 8.52786C3.48725 8.99432 3.25401 9.55507 3.25401 10.2101V11.4011Z"
                  fill="#028643"
                />
              </svg>

              <span>Delivery</span>
            </button>
            <button
              className={deliveryMode === deliveryModes[1] ? "selected" : ""}
              onClick={() => setDeliveryMode(deliveryModes[1])}
            >
              <svg
                width="21"
                height="27"
                viewBox="0 0 21 27"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.846924 26.8334V18.8334H4.84692V26.8334H0.846924ZM7.51359 26.8334V10.9667C6.40248 11.3445 5.68026 12.0389 5.34692 13.05C5.01359 14.0611 4.84692 15.1 4.84692 16.1667H2.18026C2.18026 13.3222 3.01359 11.0556 4.68026 9.36669C6.34692 7.6778 8.6247 6.83335 11.5136 6.83335C13.7358 6.83335 15.4025 6.28335 16.5136 5.18335C17.6247 4.08335 18.1803 2.41113 18.1803 0.166687H20.8469C20.8469 2.12224 20.4303 3.87224 19.5969 5.41669C18.7636 6.96113 17.4025 8.05558 15.5136 8.70002V26.8334H12.8469V18.8334H10.1803V26.8334H7.51359ZM11.5136 5.50002C10.7803 5.50002 10.1525 5.23891 9.63026 4.71669C9.10804 4.19447 8.84692 3.56669 8.84692 2.83335C8.84692 2.10002 9.10804 1.47224 9.63026 0.95002C10.1525 0.427798 10.7803 0.166687 11.5136 0.166687C12.2469 0.166687 12.8747 0.427798 13.3969 0.95002C13.9191 1.47224 14.1803 2.10002 14.1803 2.83335C14.1803 3.56669 13.9191 4.19447 13.3969 4.71669C12.8747 5.23891 12.2469 5.50002 11.5136 5.50002Z"
                  fill="black"
                />
              </svg>

              <span>Pickup</span>
            </button>
          </div>
          <div className="delivery-info">
            <span className="d-title">
              {deliveryMode === deliveryModes[0]
                ? `Add delivery address`
                : `Select pickup iCart or provide us location to route you to the nearest iCart`}
            </span>
            <div style={{ gap: 10 }} className="flex aling-center ">
              <button
                className={`selected-address ${
                  deliveryAddress?.address || selectedOutlet ? "filled" : ""
                }`}
                onClick={() => toggleLocationModal(outlets)}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_532_1222)">
                    <path
                      d="M9.99984 1.66666C6.77484 1.66666 4.1665 4.27499 4.1665 7.49999C4.1665 11.875 9.99984 18.3333 9.99984 18.3333C9.99984 18.3333 15.8332 11.875 15.8332 7.49999C15.8332 4.27499 13.2248 1.66666 9.99984 1.66666ZM5.83317 7.49999C5.83317 5.19999 7.69984 3.33332 9.99984 3.33332C12.2998 3.33332 14.1665 5.19999 14.1665 7.49999C14.1665 9.89999 11.7665 13.4917 9.99984 15.7333C8.2665 13.5083 5.83317 9.87499 5.83317 7.49999Z"
                      fill="#000"
                    />
                    <path
                      d="M9.99984 9.58332C11.1504 9.58332 12.0832 8.65058 12.0832 7.49999C12.0832 6.3494 11.1504 5.41666 9.99984 5.41666C8.84924 5.41666 7.9165 6.3494 7.9165 7.49999C7.9165 8.65058 8.84924 9.58332 9.99984 9.58332Z"
                      fill="#000"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_532_1222">
                      <rect width="20" height="20" fill="#fff" />
                    </clipPath>
                  </defs>
                </svg>
                <span>
                  {selectedOutlet
                    ? `Pickup at ${selectedOutlet.name}`
                    : deliveryAddress
                    ? deliveryAddress?.address
                    : `Search or pick your ${deliveryMode} location`}
                </span>
              </button>
              {deliveryMode === deliveryModes[1] ? (
                <button
                  onClick={openOutlets}
                  style={{
                    width: 50,
                    height: 50,
                    padding: "unset",
                    backgroundColor: "transparent",
                    background: "none",
                  }}
                >
                  <img
                    alt="iCart"
                    src={icart}
                    style={{ width: 40, height: 40, borderRadius: 8 }}
                  />
                </button>
              ) : null}
            </div>
            <input
              value={name}
              onChange={(e) => setName(e.target.value)}
              type="text"
              placeholder="Name"
            />
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              placeholder="Email"
            />
            <input
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              type="number"
              placeholder="Phone Number"
            />
          </div>
          <div className="checkout-btn">
            <button
              disabled={loading || !cart.length}
              onClick={() =>
                placeAndCompleteOrder(
                  cart,
                  deliveryMode,
                  selectedCity?._id,
                  deliveryAddress,
                  { name, phoneNumber, email },
                  selectedOutlet?._id,
                  openModal
                )
              }
              className="flex btn align-center justify-center cormorant f700"
            >
              {loading ? (
                <Loader className="white" />
              ) : (
                <span>Place Order</span>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UCart;
