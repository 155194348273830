import UCart from "../components/new-ui/Cart";
import Gradient from "../components/new-ui/Gradient";

function Cart(props) {
  return (
    <div>
      <Gradient title="Cart Items" />
      <UCart />
    </div>
  );
}

export default Cart;
