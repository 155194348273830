export const nav_links = [
  {
    no_hash: [
      {
        path: "/",
        text: "Home",
      },
      {
        path: "/track-order",
        text: "Track Order",
      },
    ],
  },
  {
    hash: [
      {
        path: "#about",
        text: "About Us",
      },
      {
        path: "#contact",
        text: "Contact Us",
      },
    ],
  },
];
