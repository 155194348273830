import Gradient from "./Gradient";
import useQuery from "../../hooks/useQuery";
import { useContext, useEffect, useState } from "react";
import useShop from "../../hooks/useShop";
import Loader from "./Loader";
import DataContext from "../../contexts/DataContext";
import { formatDateTime } from "../../utils/date";
import Image from "../Image";

const getWaitingTime = (date) => {
  date = new Date(date);
  const now = new Date();
  const diffInMs = date - now;
  const diffInSeconds = Math.floor(diffInMs / 1000);
  const diffInMinutes = Math.floor(diffInSeconds / 60);

  return diffInMinutes < 0
    ? 0
    : `${diffInMinutes} - ${diffInMinutes + Math.floor(diffInMinutes * 0.5)}`;
};

const ing = {
  pending: "queue",
  processing: "processing",
  transit: "delivery",
};

const Item = ({
  quantity,
  banner,
  name,
  status,
  description,
  priceStamp,
  formatCurrency,
  images = [],
  type,
  kiosk,
  variety,
  drink,
}) => (
  <div className="cart-item flex new-ui-card">
    <div className="menu-img">
      <Image src={images[0]} alt="" />
    </div>
    <div className="menu-details" style={{ flex: 1 }}>
      <span style={{ textAlign: "left" }} className="cart-menu-price">
        {formatCurrency(quantity * priceStamp)}
      </span>
      <span className="menu-name">{name}</span>
      <p>{description}</p>
      <div style={{ gap: 10 }} className="flex gap-10 ">
        {variety ? (
          <span
            style={{
              width: "fit-content",
              backgroundColor: "var(--alerts-success)",
            }}
            className="variety required"
          >
            {variety}
          </span>
        ) : null}
        {drink ? (
          <span
            style={{
              width: "fit-content",
              backgroundColor: "var(--alerts-info)",
            }}
            className="variety required"
          >
            {drink}
          </span>
        ) : null}
      </div>
      <br />
      <div className={`status ${status}`}>
        {status?.replace("pending", "in queue")}
      </div>

      {type === "pickup" ? (
        <p>
          Pickup at:{" "}
          <a
            style={{ textDecoration: "underline", color: "#000" }}
            href={`https://google.com/maps/?q=${kiosk.location?.lat},${kiosk.location?.lng}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {kiosk?.name}
          </a>
        </p>
      ) : null}
    </div>
    <div className="price-counter floating">{quantity}</div>
  </div>
);

const OrderTimeline = ({
  last,
  completed = false,
  title,
  description,
  date,
}) => (
  <div
    className={`order-timeline flex align-center ${last ? "last" : ""} ${
      completed ? "timeline-completed" : ""
    }`}
  >
    <div className="timeline-icon flex align-center justify-center">
      <div className="flex align-center justify-center">
        {completed ? (
          <svg
            width="26"
            height="19"
            viewBox="0 0 26 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M23.0513 0.251831L25.5994 2.79996L9.39941 19L0.399414 9.99996L2.94754 7.45183L9.39941 13.9093L23.0513 0.251831Z"
              fill="white"
            />
          </svg>
        ) : null}
      </div>
    </div>
    <div className="timeline-desc">
      <span>{title}</span>
      <span>{description}</span>
      <span>{date ? formatDateTime(date).dateTime : "-"}</span>
    </div>
  </div>
);

function TrackOrder(props) {
  const query = useQuery();
  const [id, setId] = useState(query.get("id"));
  const { getOrder, loading, order } = useShop();
  const { formatCurrency } = useContext(DataContext);

  useEffect(() => {
    if (id && id?.length === 6) getOrder(id);
    // eslint-disable-next-line
  }, [id]);
  return (
    <div>
      <Gradient
        input={{
          placeholder: "Enter Order ID",
          value: id,
          onChange: (v) => setId(v.target.value),
        }}
        title="Track Your Order"
      />
      <br />
      {loading ? (
        <div
          style={{ height: 100, paddingBottom: 80 }}
          className="flex justify-center align-center"
        >
          <Loader loading={loading} />
        </div>
      ) : order ? (
        <div className="container">
          <div className="cart-display">
            <div className="cart-items">
              <div className="flex justify-between align-center">
                <h3 className="order-text">Your Order</h3>
              </div>
              <br />
              <br />
              {order.kioskOrders.map((__) =>
                __.items.map((_) => (
                  <Item
                    {..._.item}
                    drink={_.drink}
                    variety={_.variety}
                    kiosk={__.kiosk}
                    type={order.type}
                    status={__.status}
                    quantity={_.quantity}
                    priceStamp={_.priceStamp}
                    formatCurrency={formatCurrency}
                  />
                ))
              )}
            </div>
            <div className="preview preview-2">
              <div className="p-head">
                {order.status === "pending"
                  ? "Order Placed"
                  : order.status === "processing"
                  ? "Order is processing"
                  : order.status === "delivered"
                  ? "Order Delivered"
                  : "Delivery in Progress"}
                <br />
                {order.status === "delivered" ? (
                  <span>Order delivered!</span>
                ) : (
                  <span>
                    Estimated {ing[order.status]} time:{" "}
                    <strong>
                      {/* {getWaitingTime(
                        (order.status === "pending"
                          ? order.estimatedQueueTime
                          : order.status === "processing"
                          ? order.estimatedProcessingTime
                          : order.estimatedDeliveryTime)
                      )} */}
                      {getWaitingTime(
                        order.status === "pending"
                          ? order.estimatedQueueTime
                          : order.status === "processing"
                          ? order.estimatedProcessingTime
                          : order.estimatedDeliveryTime
                      )}
                      mins
                    </strong>
                  </span>
                )}
              </div>
              <OrderTimeline
                date={order.createdAt}
                description="iCart Africa have recieved your order."
                title="Order Placed"
                completed={order.createdAt}
              />
              <OrderTimeline
                description="iCart Africa have accepeted your order."
                title="Order Accepted"
                completed={order.status !== "pending"}
                date={order.acceptedAt || order.estimatedQueueTime}
              />
              <OrderTimeline
                description="Your order have been completed"
                title="Order Completed"
                completed={
                  order.status !== "pending" && order.status !== "processing"
                }
                date={order.completedAt || order.estimatedProcessingTime}
              />
              <OrderTimeline
                description="Your order have been delivereed!"
                title="Order Delivered"
                last
                date={order.deliveredAt || order.estimatedDeliveryTime}
                completed={order.status === "delivered"}
              />
              {order.rider ? (
                <div className="rider-info flex justify-between align-center">
                  <div className="rider-img">
                    <Image src={order.rider.profileImage} alt="" />
                  </div>
                  <div className="rider-details ">
                    <span>Rider Details</span>
                    <span className="rider-name">
                      {order.rider.firstName} {order.rider.lastName}
                    </span>
                    <span>2+ rides completed</span>
                  </div>
                  <a href={`tel:${order.rider.phoneNumber}`} className="phone">
                    <svg
                      width="23"
                      height="22"
                      viewBox="0 0 23 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M22.2002 15.052L15.8762 14.32L12.8522 17.344C9.45618 15.616 6.67218 12.844 4.94418 9.43598L7.98018 6.39998L7.24818 0.0999756H0.636176C-0.0598239 12.316 9.98418 22.36 22.2002 21.664V15.052Z"
                        fill="white"
                      />
                    </svg>
                  </a>
                </div>
              ) : null}
              <div className="delivery-address">
                <span>
                  {order.type === "pickup" ? "Pick iCart" : "Delivery Address"}{" "}
                  :
                </span>
                <p>
                  {order.type === "pickup"
                    ? order.kioskOrders.map((_) => (
                        <a
                          style={{ textDecoration: "underline", color: "#000" }}
                          href={`https://google.com/maps/?q=${_.kiosk.location?.lat},${_.kiosk.location?.lng}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {_.kiosk?.name}
                        </a>
                      ))
                    : order.deliveryTo.address}
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default TrackOrder;
