import client from "../api";
import urls from "../api/urls";

export function generateUUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    /[xy]/g,
    function (char) {
      const random = (Math.random() * 16) | 0; // Random number between 0 and 15
      const value = char === "x" ? random : (random & 0x3) | 0x8; // Ensure 'y' bits match 8, 9, A, or B
      return value.toString(16); // Convert to hexadecimal
    }
  );
}

export const formatNumber = (n, pre = "₦") =>
  (pre || "") + n?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const getImgUrl = (url) =>
  url
    ? client.getUri() + urls.files.baseUrl + url
    : "https://www.shutterstock.com/image-vector/image-icon-600nw-211642900.jpg";

export const addToFormData = (object) => {
  const formData = new FormData();
  for (const key in object) {
    if (Object.hasOwnProperty.call(object, key)) {
      formData.append(key, object[key]);
    }
  }
  return formData;
};

export async function reverseGeocode(lat, lng) {
  const apiKey = "AIzaSyBKHZ5C24eYH-MccKBSniBl3mT5MjBhJYY"; // Replace with your actual API key
  const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`;

  try {
    const response = await fetch(url);
    if (response.ok) {
      const data = await response.json();
      if (data.results && data.results.length > 0) {
        return data.results[0];
        // return JSON.stringify(data.results[0].address_components[1].long_name);
        // return JSON.stringify(data.results[0].formatted_address);
      } else {
        return null;
      }
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
}

export function capitalizeFirstLetter(string) {
  if (string.length === 0) return string; // handle empty strings
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function addParamsToUrl(url, params) {
  const isRelativeUrl = !/^https?:\/\//i.test(url);
  const baseUrl = isRelativeUrl ? "http://placeholder.com" : url;

  const urlObject = new URL(url, baseUrl);

  Object.keys(params).forEach((key) => {
    if (params[key]) urlObject.searchParams.append(key, params[key]);
  });

  return isRelativeUrl
    ? urlObject.pathname + urlObject.search
    : urlObject.toString();
}

export const formatIntCurrency = (amount, countryCode, currencyCode) => {
  if (!countryCode || !currencyCode) return formatNumber(amount, "");
  try {
    return new Intl.NumberFormat(countryCode, {
      style: "currency",
      currency: currencyCode,
    }).format(amount);
  } catch (error) {
    return formatNumber(amount, "");
  }
};
